<template>
  <vx-card class="my-5">
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4>Invoices Summary Report</h4>
    </div>

    <div class="">
      <b-table-simple hover bordered outlined responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>{{ "" }}</b-th>
            <b-th>Name of User(s)</b-th>
            <b-th>Number of Invoices</b-th>
            <b-th>Amount</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr>
            <b-td>Total Budget</b-td>
            <b-td>{{ reportData.total_budget.user_names }}</b-td>
            <b-td>{{ reportData.total_budget.number_of_invoices }}</b-td>
            <b-td class="font-bold">{{ reportData.total_budget.amount }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Approved invoices</b-td>
            <b-td>{{ reportData.approved.user_names }}</b-td>
            <b-td>{{ reportData.approved.number_of_invoices }}</b-td>
            <b-td>{{ reportData.approved.amount }}</b-td>
          </b-tr>
          <b-tr
            v-for="(pending_invoice, index) in reportData.pending_invoices"
            :key="index + 'pending'"
          >
            <b-td>Pending at Level {{ pending_invoice.user_level }}</b-td>
            <b-td>{{ pending_invoice.user_names }}</b-td>
            <b-td>{{ pending_invoice.number_of_invoices }}</b-td>
            <b-td>{{ pending_invoice.amount }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Total</b-td>
            <b-td>{{ reportData.total.user_names }}</b-td>
            <b-td class="font-bold">
              {{ reportData.total.number_of_invoices }}
            </b-td>
            <b-td class="font-bold">{{ reportData.total.amount }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Balance Budget</b-td>
            <b-td>{{ reportData.balance_budget.user_names }}</b-td>
            <b-td>{{ reportData.balance_budget.number_of_invoices }}</b-td>
            <b-td class="font-bold">
              {{ reportData.balance_budget.amount }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </vx-card>
</template>

<script>
import ReportService from "@/services/reportService.js";

export default {
  props: {
    project_id: {
      type: Number,
      required: true,
    },
  },

  watch: {
    project_id() {
      this.getReportData();
    },
  },

  data() {
    return {
      reportData: [],
    };
  },

  methods: {
    getReportData() {
      if (!this.project_id) return;
      this.$vs.loading();
      let payload = { project_id: this.project_id };
      console.log("Salman is the best => ", payload);
      ReportService.getInvoiceSummaryReport({ project_id: this.project_id })
        .then((response) => {
          this.$vs.loading.close();
          if (!response.data.Status) {
            return this.$vs.notify({
              title: "Invoice Summary!",
              text: response.data.Message || "Data not found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          this.reportData = response.data.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
  },

  beforeMount() {
    this.getReportData();
  },
};
</script>

<style></style>
